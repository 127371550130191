import styled from "styled-components";

export const Form = styled.div`
  width: 300px;  
`

export const PayButton = styled.button`
  width: 100%;
  height: 48px;
  
  margin-top: 24px;
  
  font-weight: 600;
  color: white;
  
  background: #3988e5;
  
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
  
  cursor: pointer;
`